import React, { Fragment } from "react"
import Layout from "../components/layout"

export default () => (
  <Fragment>
    <Layout>
      <div className="container">
        <h1> Contact me</h1>
        <p>hello@info.tech</p>
      </div>
    </Layout>
  </Fragment>
)
