import React from "react"
import { Link } from "gatsby"

import "./layout.scss"

export default ({ children }) => (
  <div>
    <nav class="navbar navbar-expand-sm navbar-light bg-light ">
      <div class="container">
        <Link className="navbar-brand" to="/">
          <svg
            width="30"
            height="30"
            viewBox="0 0 428.85 428.85"
            fill="#1abc9c"
          >
            <g>
              <g>
                <path
                  d="M109.8,335.483c-2.16-23.56-13-40.8-24-58.48c-21.76-34.52-44.8-71.08,7.2-164.72c1.883-3.568,0.523-7.987-3.04-9.88
           c-3.532-1.967-7.99-0.698-9.957,2.834c-0.001,0.002-0.002,0.004-0.003,0.006c-56.24,101.16-30.8,141.44-6.8,179.56
           c10.2,16,20,32,22,52c0.365,4.032,3.928,7.005,7.96,6.64S110.165,339.515,109.8,335.483z"
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  d="M286.52,112.563c-2.33,6.407-5.621,12.423-9.76,17.84c-7.293,9.826-17.89,16.687-29.84,19.32
           c-12.72,2.76-28,1.04-45.44-6.84c-25.24-11.4-54.64-35.32-86.96-76.48c18.4-1.88,51.08-7.8,75.44-26.32
           c20.6-15.68,39.2-21.44,54.6-20c6.617,0.526,13.037,2.506,18.8,5.8c5.687,3.296,10.653,7.704,14.6,12.96
           c6.327,8.616,10.522,18.609,12.24,29.16c5.218,0.982,10.364,2.318,15.4,4c-1.414-15.256-6.944-29.842-16-42.2
           c-5.161-6.841-11.651-12.569-19.08-16.84c-7.639-4.366-16.149-6.988-24.92-7.68c-18.8-1.56-40.88,5.04-64.68,23.16
           c-29.84,22.68-74.76,24-80.28,24c-4.056-0.266-7.56,2.806-7.826,6.862c-0.114,1.737,0.391,3.458,1.426,4.858
           c37.44,50.04,71.72,78.6,101.16,92c20.56,9.24,38.92,11.2,54.56,8c15.406-3.384,29.063-12.237,38.44-24.92
           c4.923-6.514,8.847-13.727,11.64-21.4l-1.16-0.56C294.898,115.369,290.764,113.79,286.52,112.563z"
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  d="M392.68,175.883c-59.6-99.32-107.2-97.48-152.2-95.72c-19.12,0.76-37.72,1.48-56-6.96
           c-3.816-1.335-7.992,0.677-9.326,4.493c-1.149,3.286,0.175,6.927,3.166,8.707c21.48,9.88,41.8,9.12,62.68,8.28
           c40.8-1.44,84-3.12,139.08,88.72c2.077,3.479,6.581,4.617,10.06,2.54C393.619,183.867,394.757,179.363,392.68,175.883z"
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  d="M161.6,285.803c-2.24-22.4-9.76-39.28-20.52-51.16c-10.597-11.64-25.045-19.055-40.68-20.88
           c-8.091-1.012-16.288-0.81-24.32,0.6c0,0.44,0,0.84,0,1.28c0.346,4.453,1.069,8.869,2.16,13.2
           c6.693-1.209,13.531-1.398,20.28-0.56c12.128,1.353,23.362,7.034,31.64,16c8.72,9.64,14.88,23.72,16.8,42.76
           c2.76,27.52-3.24,64.92-22.64,113.68c-10.84-15.04-32.32-40.4-60.52-52.24c-24-10.04-38.2-23.2-44.76-37.16
           c-2.879-5.926-4.422-12.412-4.52-19c0.033-6.59,1.394-13.107,4-19.16c4.295-9.792,10.847-18.427,19.12-25.2
           c-1.683-5.05-3.02-10.208-4-15.44c-12.514,8.848-22.386,20.933-28.56,34.96c-3.345,7.854-5.073,16.303-5.08,24.84
           c0.036,8.804,2.016,17.491,5.8,25.44c8,17.08,24.84,32.92,52.36,44.48c36.72,15.36,61.12,57.4,61.36,57.84
           c1.998,3.515,6.466,4.744,9.981,2.747c1.41-0.802,2.515-2.049,3.139-3.547C157.24,361.883,164.84,317.923,161.6,285.803z"
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  d="M369.86,272.183c-2.331-3.314-6.906-4.111-10.22-1.78c-19.32,13.64-28.8,31.68-38.56,50.2
           c-19.04,36-39.16,74.36-146.24,76c-4.043,0.066-7.266,3.397-7.2,7.44s3.397,7.266,7.44,7.2c115.8-1.92,138-44,159-84
           c8.88-16.84,17.56-33.32,34-44.84C371.394,280.073,372.191,275.497,369.86,272.183z"
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  d="M425.137,209.362c-1.363-0.778-2.939-1.1-4.497-0.919l0.2,0.2c-62.04,7.36-103.92,22.8-130.32,41.76
           c-18.28,13.16-29.16,28-34.04,43.32c-4.783,14.985-3.988,31.195,2.24,45.64c3.181,7.516,7.468,14.513,12.72,20.76l1.04-0.72
           c3.686-2.53,7.152-5.366,10.36-8.48c-4.378-5.168-7.951-10.966-10.6-17.2c-4.858-11.269-5.469-23.916-1.72-35.6
           c4-12.4,13.08-24.72,28.64-36c22.44-16,57.8-29.64,109.72-37.2c-7.56,16.92-18.8,48-14.92,78.48c3.28,25.72-1,44.68-9.84,57.4
           c-3.759,5.459-8.676,10.02-14.4,13.36c-5.71,3.279-12.023,5.374-18.56,6.16c-10.636,1.166-21.396-0.205-31.4-4
           c-3.512,4.001-7.294,7.757-11.32,11.24c13.911,6.4,29.298,8.902,44.52,7.24c8.455-1.034,16.616-3.754,24-8
           c7.605-4.438,14.134-10.503,19.12-17.76c10.76-15.52,16-37.92,12.32-67.56c-5.04-39.6,19.4-81.96,19.48-82.08
           C429.895,215.873,428.667,211.378,425.137,209.362z"
                />
              </g>
            </g>
          </svg>
          <h3 className="d-inline"> sachi</h3>
        </Link>
        <div class="navbar justify-content-end">
          <nav class="nav mt-2 mt-lg-0 justify-content-center ">
            <li class="nav-item">
              <Link
                to="/portfolio"
                className="nav-link grey-text"
                activeClassName="active-web"
              >
                Portfolio
              </Link>
            </li>
            <li class="nav-item">
              <Link
                to="/about"
                className="nav-link grey-text"
                activeClassName="active-web"
              >
                About
              </Link>
            </li>
          </nav>
        </div>
      </div>
    </nav>

    {children}
  </div>
)

// export default ({ children }) => (
//   <div>
//     <nav class="navbar navbar-expand-lg navbar-light bg-light ">
//       <div class="container">
//         <Link className="navbar-brand" to="/">
//           <svg
//             width="36"
//             height="36"
//             viewBox="0 0 428.85 428.85"
//             fill="#1abc9c"
//           >
//             <g>
//               <g>
//                 <path
//                   d="M109.8,335.483c-2.16-23.56-13-40.8-24-58.48c-21.76-34.52-44.8-71.08,7.2-164.72c1.883-3.568,0.523-7.987-3.04-9.88
//            c-3.532-1.967-7.99-0.698-9.957,2.834c-0.001,0.002-0.002,0.004-0.003,0.006c-56.24,101.16-30.8,141.44-6.8,179.56
//            c10.2,16,20,32,22,52c0.365,4.032,3.928,7.005,7.96,6.64S110.165,339.515,109.8,335.483z"
//                 />
//               </g>
//             </g>
//             <g>
//               <g>
//                 <path
//                   d="M286.52,112.563c-2.33,6.407-5.621,12.423-9.76,17.84c-7.293,9.826-17.89,16.687-29.84,19.32
//            c-12.72,2.76-28,1.04-45.44-6.84c-25.24-11.4-54.64-35.32-86.96-76.48c18.4-1.88,51.08-7.8,75.44-26.32
//            c20.6-15.68,39.2-21.44,54.6-20c6.617,0.526,13.037,2.506,18.8,5.8c5.687,3.296,10.653,7.704,14.6,12.96
//            c6.327,8.616,10.522,18.609,12.24,29.16c5.218,0.982,10.364,2.318,15.4,4c-1.414-15.256-6.944-29.842-16-42.2
//            c-5.161-6.841-11.651-12.569-19.08-16.84c-7.639-4.366-16.149-6.988-24.92-7.68c-18.8-1.56-40.88,5.04-64.68,23.16
//            c-29.84,22.68-74.76,24-80.28,24c-4.056-0.266-7.56,2.806-7.826,6.862c-0.114,1.737,0.391,3.458,1.426,4.858
//            c37.44,50.04,71.72,78.6,101.16,92c20.56,9.24,38.92,11.2,54.56,8c15.406-3.384,29.063-12.237,38.44-24.92
//            c4.923-6.514,8.847-13.727,11.64-21.4l-1.16-0.56C294.898,115.369,290.764,113.79,286.52,112.563z"
//                 />
//               </g>
//             </g>
//             <g>
//               <g>
//                 <path
//                   d="M392.68,175.883c-59.6-99.32-107.2-97.48-152.2-95.72c-19.12,0.76-37.72,1.48-56-6.96
//            c-3.816-1.335-7.992,0.677-9.326,4.493c-1.149,3.286,0.175,6.927,3.166,8.707c21.48,9.88,41.8,9.12,62.68,8.28
//            c40.8-1.44,84-3.12,139.08,88.72c2.077,3.479,6.581,4.617,10.06,2.54C393.619,183.867,394.757,179.363,392.68,175.883z"
//                 />
//               </g>
//             </g>
//             <g>
//               <g>
//                 <path
//                   d="M161.6,285.803c-2.24-22.4-9.76-39.28-20.52-51.16c-10.597-11.64-25.045-19.055-40.68-20.88
//            c-8.091-1.012-16.288-0.81-24.32,0.6c0,0.44,0,0.84,0,1.28c0.346,4.453,1.069,8.869,2.16,13.2
//            c6.693-1.209,13.531-1.398,20.28-0.56c12.128,1.353,23.362,7.034,31.64,16c8.72,9.64,14.88,23.72,16.8,42.76
//            c2.76,27.52-3.24,64.92-22.64,113.68c-10.84-15.04-32.32-40.4-60.52-52.24c-24-10.04-38.2-23.2-44.76-37.16
//            c-2.879-5.926-4.422-12.412-4.52-19c0.033-6.59,1.394-13.107,4-19.16c4.295-9.792,10.847-18.427,19.12-25.2
//            c-1.683-5.05-3.02-10.208-4-15.44c-12.514,8.848-22.386,20.933-28.56,34.96c-3.345,7.854-5.073,16.303-5.08,24.84
//            c0.036,8.804,2.016,17.491,5.8,25.44c8,17.08,24.84,32.92,52.36,44.48c36.72,15.36,61.12,57.4,61.36,57.84
//            c1.998,3.515,6.466,4.744,9.981,2.747c1.41-0.802,2.515-2.049,3.139-3.547C157.24,361.883,164.84,317.923,161.6,285.803z"
//                 />
//               </g>
//             </g>
//             <g>
//               <g>
//                 <path
//                   d="M369.86,272.183c-2.331-3.314-6.906-4.111-10.22-1.78c-19.32,13.64-28.8,31.68-38.56,50.2
//            c-19.04,36-39.16,74.36-146.24,76c-4.043,0.066-7.266,3.397-7.2,7.44s3.397,7.266,7.44,7.2c115.8-1.92,138-44,159-84
//            c8.88-16.84,17.56-33.32,34-44.84C371.394,280.073,372.191,275.497,369.86,272.183z"
//                 />
//               </g>
//             </g>
//             <g>
//               <g>
//                 <path
//                   d="M425.137,209.362c-1.363-0.778-2.939-1.1-4.497-0.919l0.2,0.2c-62.04,7.36-103.92,22.8-130.32,41.76
//            c-18.28,13.16-29.16,28-34.04,43.32c-4.783,14.985-3.988,31.195,2.24,45.64c3.181,7.516,7.468,14.513,12.72,20.76l1.04-0.72
//            c3.686-2.53,7.152-5.366,10.36-8.48c-4.378-5.168-7.951-10.966-10.6-17.2c-4.858-11.269-5.469-23.916-1.72-35.6
//            c4-12.4,13.08-24.72,28.64-36c22.44-16,57.8-29.64,109.72-37.2c-7.56,16.92-18.8,48-14.92,78.48c3.28,25.72-1,44.68-9.84,57.4
//            c-3.759,5.459-8.676,10.02-14.4,13.36c-5.71,3.279-12.023,5.374-18.56,6.16c-10.636,1.166-21.396-0.205-31.4-4
//            c-3.512,4.001-7.294,7.757-11.32,11.24c13.911,6.4,29.298,8.902,44.52,7.24c8.455-1.034,16.616-3.754,24-8
//            c7.605-4.438,14.134-10.503,19.12-17.76c10.76-15.52,16-37.92,12.32-67.56c-5.04-39.6,19.4-81.96,19.48-82.08
//            C429.895,215.873,428.667,211.378,425.137,209.362z"
//                 />
//               </g>
//             </g>
//           </svg>

//           <h2 className="d-inline"> sachi</h2>
//         </Link>
//         <div class="collapse navbar-collapse mx-auto justify-content-end">
//           <ul class="navbar-nav mt-2 mt-lg-0 ">
//             <li class="nav-item">
//               <Link
//                 to="/portfolio"
//                 className="nav-link"
//                 activeClassName="active-web"
//               >
//                 Portfolio
//               </Link>
//             </li>
//             <li class="nav-item">
//               <Link
//                 to="/about"
//                 className="nav-link"
//                 activeClassName="active-web"
//               >
//                 About
//               </Link>
//             </li>
//           </ul>
//         </div>
//       </div>
//     </nav>
//     <div class="d-lg-none">
//       <nav class="nav fixed-bottom navbar-light bg-light justify-content-around">
//         <Link
//           className="nav-link bottom-nav-icon grey-text"
//           to="/portfolio"
//           activeClassName="active-mobile"
//         >
//           <svg width="24" height="24" viewBox="0 0 24 24" fill="grey">
//             <path d="M3 17C3 17.5523 3.44772 18 4 18H20C20.5523 18 21 17.5523 21 17V17C21 16.4477 20.5523 16 20 16H4C3.44772 16 3 16.4477 3 17V17ZM3 12C3 12.5523 3.44772 13 4 13H20C20.5523 13 21 12.5523 21 12V12C21 11.4477 20.5523 11 20 11H4C3.44772 11 3 11.4477 3 12V12ZM4 6C3.44772 6 3 6.44772 3 7V7C3 7.55228 3.44772 8 4 8H20C20.5523 8 21 7.55228 21 7V7C21 6.44772 20.5523 6 20 6H4Z"></path>
//           </svg>

//           <div>Portfolio</div>
//         </Link>
//         <Link
//           className="nav-link bottom-nav-icon grey-text"
//           to="/about"
//           activeClassName="active-mobile"
//         >
//           <svg width="24" height="24" viewBox="0 0 24 24" fill="grey">
//             <path d="M3 17C3 17.5523 3.44772 18 4 18H20C20.5523 18 21 17.5523 21 17V17C21 16.4477 20.5523 16 20 16H4C3.44772 16 3 16.4477 3 17V17ZM3 12C3 12.5523 3.44772 13 4 13H20C20.5523 13 21 12.5523 21 12V12C21 11.4477 20.5523 11 20 11H4C3.44772 11 3 11.4477 3 12V12ZM4 6C3.44772 6 3 6.44772 3 7V7C3 7.55228 3.44772 8 4 8H20C20.5523 8 21 7.55228 21 7V7C21 6.44772 20.5523 6 20 6H4Z"></path>
//           </svg>
//           <div>About</div>
//         </Link>
//       </nav>
//     </div>
//     {children}
//   </div>
// )

// <Link
//   className="nav-link bottom-nav-icon grey-text"
//   to="/contact"
//   activeClassName="active-mobile"
// >
//   <svg width="24" height="24" viewBox="0 0 24 24" fill="grey">
//     <path d="M3 17C3 17.5523 3.44772 18 4 18H20C20.5523 18 21 17.5523 21 17V17C21 16.4477 20.5523 16 20 16H4C3.44772 16 3 16.4477 3 17V17ZM3 12C3 12.5523 3.44772 13 4 13H20C20.5523 13 21 12.5523 21 12V12C21 11.4477 20.5523 11 20 11H4C3.44772 11 3 11.4477 3 12V12ZM4 6C3.44772 6 3 6.44772 3 7V7C3 7.55228 3.44772 8 4 8H20C20.5523 8 21 7.55228 21 7V7C21 6.44772 20.5523 6 20 6H4Z"></path>
//   </svg>
//   <div>Contact</div>
// </Link>

// <li class="nav-item">
// <Link
//   to="/contact"
//   className="nav-link"
//   activeClassName="active-web"
// >
//   Contact
// </Link>
// </li>
